<template>
    <div class="helpcenterdetail">
        <div :class="['fixed-left',{absolute:isLeftAbsolute}]" :style="fixeLeftHeight">
            <h2>帮助大标题</h2>
            <ul>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
            </ul>
            <h2>帮助大标题</h2>
            <ul>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
            </ul>
            <h2>帮助大标题</h2>
            <ul>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
                <li>
                    帮助小标题
                </li>
            </ul>
        </div>
        <div class="right-content">
            帮助具体内容
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                screenHeight: 0,
                scrollTop:0,
                pageHeight:0,
                isLeftAbsolute:false,
            }
        },
        computed:{
            fixeLeftHeight(){
                return {
                    height:this.screenHeight - 78 + 'px'
                }
            }
        },
        watch:{
            scrollTop(newVal){
                let {screenHeight,pageHeight} = this
                let critical = pageHeight - screenHeight - 286
                this.isLeftAbsolute = newVal >= critical ? true : false
            }
        },
        methods: {
            handleScroll(){
                let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                this.scrollTop = scrollTop
            },
            handleResize(){
                this.screenHeight = window.innerHeight
            }
        },
        mounted(){
            window.addEventListener('scroll',this.handleScroll,true)
            window.addEventListener('resize',this.handleResize,true)
            let  h = document.documentElement.scrollHeight || document.body.scrollHeight
            this.pageHeight = h
            this.screenHeight = window.innerHeight
        },
        destroyed(){
            window.removeEventListener('scroll',this.handleScroll)
            window.addEventListener('resize',this.handleResize)
        }
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .helpcenterdetail{
        background: #fefefe;
        position: relative;
        .fixed-left{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 252px;
            border-right: 1px solid #dedede;
            padding: 30px;
            overflow: auto;
            &.absolute{
                position: absolute;
            }
            h2{
                font-size: 20px;
                font-weight: 600;
                margin: 20px;
            }
            ul{
                text-align: center;
                li{
                    padding: 6px;
                    color: #787878;
                    cursor: pointer;
                    transition: .1s;
                    &:hover{
                        color: @themeColor;
                    }
                }
            }
        }
        .right-content{
            padding: 30px 30px 30px 280px;
            height: 1800px;
        }
    }
</style>